import { jwtDecode } from "jwt-decode"

class Authentication {
  constructor() {
    this.article_id = window.location.pathname;
    //-- Staging --//
    this.baseURL = "https://authenticate-dev.montelnews.com"
    this.applicationID = "dd44303d-539a-4e81-9fb8-029d9b83e2f3"
    //-- Production  --//
    // this.baseURL = "https://authenticate.montelnews.com"
    // this.applicationID = "bbecba16-9932-419a-8670-d9d8ae209148"
    this.validate();
  }

  showContent() {
    const container = document.querySelector(".article__body");
    fetch(
      `/api/news/${this.get_article_id()}/content?language=${this.get_language()}`,
    ).then((data) => {
      data.text().then((content) => (container.innerHTML = content));
    });
    let footer_bylines = document.querySelector("footer.article__bylines");
    footer_bylines.classList.add("show");
  }

  get_article_id() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return urlFragments[2];
    }
    return urlFragments[3];
  }

  is_article() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments.includes("news")) {
      return true;
    }
    return false;
  }

  get_language() {
    let urlFragments = location.pathname.split("/");
    if (urlFragments[1] == "news") {
      return "en";
    }
    return urlFragments[1];
  }

  validate() {
    fetch(
      `${this.baseURL}/validate?applicationId=${this.applicationID}`,
      { credentials: "include" },
    ).then((res) => {
      if (res.status === 200) {
        // Validated
        res.text().then(token => {
          const email = jwtDecode(token).email
          window.dataLayer = window.dataLayer || [];
          window.dataLayer.push({
            'customerId': email,
          });
        })

        const el = document.querySelector('.logged-in-email')
        if (el) el.innerHTML = email
        this.showLoggedInButtons()
        const trials = document.querySelectorAll(".nav-trial");
        if (trials.length > 0) {
          trials.forEach((trial) => trial.remove());
        }
        if (this.is_article()) {
          this.showContent();
        }
      } else {
        // Try to refresh token
        fetch(
          `${this.baseURL}/refresh?applicationId=${this.applicationID}`,
          { credentials: "include" },
        )
        .then((res) => {
          if (res.ok) {
            // Has refresh token, i.e logged in
            res.text().then(token => {
              const email = jwtDecode(token).email
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                'customerId': email,
              });

              const el = document.querySelector('.logged-in-email')
              if (el) el.innerHTML = email
            })
            this.showLoggedInButtons()
            const trials = document.querySelectorAll(".nav-trial");
            if (trials.length > 0) {
              trials.forEach((trial) => trial.remove());
            }
            if (this.is_article()) {
              this.showContent();
            }
          } else {
            // Can't refresh, not logged in
            this.showLoggedOutButtons()
          }
        })
      }
    })
    .catch(error => {
      if (window.location.host === 'montelnews.test') {
        this.showLoggedOutButtons()
      }
    })
  }

  showLoggedInButtons() {
    // Show avatar, show logout
    document.querySelector(".avatar").classList.remove("avatar--hidden")
    document.querySelectorAll('.button--logout').forEach(button => button.classList.remove('button--hidden'))
    document.querySelectorAll('.button--register').forEach(button => button.classList.add('button--hidden'))
    document.querySelectorAll('.button--login').forEach(button => button.classList.add('button--hidden'))
  }

  showLoggedOutButtons() {
    // Show login, show register
    document.querySelectorAll('.button--register').forEach(button => button.classList.remove('button--hidden'))
    document.querySelectorAll('.button--login').forEach(button => button.classList.remove('button--hidden'))
    document.querySelector(".avatar").classList.add("avatar--hidden")
    document.querySelectorAll('.button--logout').forEach(button => button.classList.add('button--hidden'))
  }

  freeArticle(id) {
    fetch(`/api/article/${id}/access`).then((res) => {
      if (res.status == 200) {
        res.text().then((data) => {
          if (data === "FREE") {
            return true;
          }
        });
      }
    });
    return false;
  }
}

export default Authentication;